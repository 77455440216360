const videos = [
    {
      id: 1,
      title: "Elephant",
      video: "/videos/elephant.mp4",
      thumbnail: "/thumbs/thumb0001.jpg",
      description: "First depth video - an Elephant",
      likes: 100,
      views: 1000
    },
    {
        id: 2,
        title: "Italy",
        video: "/videos/italy.mp4",
        thumbnail: "/thumbs/thumb0002.jpg",
        description: "Stunning Italy",
        likes: 100,
        views: 1000
      }, 
    // ... more video objects
  ];
  
  export default videos;
  